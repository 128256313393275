<template>
    <div v-if="detailEmailLog">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card">
                    <div class="card-header card-header-flex pb-2">
                        <div class="w-100 mt-2">
                            <div class="row">
                                <div class="col-10">
                                    <h5 class="mt-3 ml-0 mr-3 mb-2">
                                        <strong>
                                            <template>{{$t('title.emailDetails')}}</template>
                                        </strong>
                                    </h5>
                                </div>
                                <b-col lg="2" md="2" sm="12" class="text-right">
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleCancelClick"
                                              v-b-tooltip.hover :title="$t('button.title.back')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                    </b-button>
                                </b-col>
                            </div><!-- /.row -->
                        </div><!-- /.w-100 -->
                    </div><!-- /.card-header -->
                    <div class="card-body">
                        <b-row>
                            <b-col md="6" sm="12">
                                <table class="table table-striped">
                                    <tbody>
                                    <tr>
                                        <th>{{$t('column.sentTo')}}</th>
                                        <td> {{detailEmailLog.sent_to}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{$t('column.subject')}}</th>
                                        <td>{{detailEmailLog.subject}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{$t('column.process')}}</th>
                                        <td>{{detailEmailLog.process}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{$t('column.sentAt')}}</th>
                                        <td>{{$global.utcDateToLocalDate(detailEmailLog.created_at) }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </b-col><!-- /.col -->
                            <b-col md="6" sm="12">
                                <span class="font-size-18 font-weight-bold text-capitalize mb-2">{{$t('title.attachments')}}</span>
                                <table class="table table-striped table-sm">
                                    <tbody>
                                    <tr v-for="(cd, index) in detailEmailLog.attachments">
                                        <td width="50">
                                            <b-button :title="$t('button.download')"
                                                      v-b-tooltip.hover
                                                      variant="outline-primary"
                                                      class="ml-2 ml-2 btn-sm"
                                                      v-if="cd.path"
                                                      :href="cd.path"
                                                      target="_blank">
                                                <i class="fa fa-cloud-download"></i>
                                            </b-button>
                                        </td>
                                        <th>{{cd.name}}</th>
                                    </tr>
                                    </tbody>
                                    <tfoot v-show="detailEmailLog.attachments.length <= 0">
                                    <tr>
                                        <th colspan="2">{{$t('title.noDataAvailable')}}</th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </b-col>
                            <b-col sm="12" class="mt-3">
                                <h4><span class="text-capitalize">{{$t('title.content')}}</span></h4>
                                <hr>
                                <b-row v-html="detailEmailLog.content">

                                </b-row><!-- /.row -->
                            </b-col>
                        </b-row><!-- /.row -->
                    </div><!-- /.card-body-->
                </div><!-- /.card -->
            </b-col>
        </b-row>
    </div><!--/div-->
</template>
<script>
import {request} from "@/util/Request";

export default {
    props: ['id', 'handleCloseOperation'],
    data() {
        return {
            detailEmailLog: null,
            items: []
        }
    },
    mounted() {
        if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
            this.getDetail(this.$route.query.oToken)
        } else {
            this.serverError()
        }
    },
    methods: {
        async getDetail(id) {
            await request({
                url: `email/logs/detail/${id}`,
            })
                .then((response) => {
                    const {data} = response
                    this.detailEmailLog = data
                })
                .catch((error) => {

                })
        },
        handleCancelClick() {
            this.handleCloseOperation()
        }
    }
}
</script>
